import React, { useEffect, useRef } from 'react';
import logo from './logo-s.svg';
import './App.css';

const App = () => {
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current === null) return;
    // @ts-ignore
    divRef.current.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
  }, [divRef]);

  return (
    <div className="App" ref={divRef}>
      <div className="neon-body">
        <h1 className="neonText">
          <span>kuetify.com</span>
        </h1>
      </div>
      <div className="header-one">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
          <p className="coming-soon">COMING SOON</p>
        </header>
      </div>
    </div>
  );
};

export default App;
